<template>
  <div class="casino-tabs-wrapper">
    <div class="casino-tabs">
      <template v-if="isLoading || !isMounted">
        <StSkeletonLoader
          bg-color="var(--background-primary)"
          height="38px"
          width="100%"
          :shimmer-opacity="0.25"
        />
        <StSkeletonLoader
          bg-color="var(--background-primary)"
          height="32px"
          width="650px"
          :shimmer-opacity="0.25"
        />
      </template>
      <template v-else>
        <div class="casino-top-tabs">
          <NuxtI18nLink
            v-for="tab in topTabs"
            :key="tab.link"
            class="casino-top-tab"
            :data-t="`casino-top-tab-${tab.link.split('/').pop()}-xsm5`"
            :class="{ active: tab.isActive }"
            prefetch
            :to="tab.link"
          >
            {{ tab.name }}
          </NuxtI18nLink>
        </div>
        <div v-show="bottomTabs.length" class="casino-bottom-tabs-wrapper">
          <StSlider
            class="casino-bottom-tabs"
            disable-arrows
            data-t="casino-bottom-tabs-uzk2"
          >
            <StSliderItem v-for="bottomTab in bottomTabs" :key="bottomTab.link">
              <NuxtI18nLink
                class="casino-bottom-tab"
                :data-t="`casino-bottom-tab-${bottomTab.link
                  .split('/')
                  .pop()}-8r4i`"
                active-class="active"
                :to="bottomTab.link"
                prefetch
              >
                <StIcon
                  v-if="bottomTab.iconLink"
                  size="16"
                  :icon-link="bottomTab.iconLink"
                  class="bottom-tab-icon"
                />
                <StIcon v-else :name="bottomTab.icon" :size="16" />
                <span class="casino-bottom-tab-name">{{ bottomTab.name }}</span>
              </NuxtI18nLink>
            </StSliderItem>
          </StSlider>
          <div class="filters">
            <slot />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useIsOnMounted } from '@st/use/composables'
import { useCasinoTabs } from './useCasinoTabs'

const { topTabs, bottomTabs, isLoading } = useCasinoTabs({
  isLobbyEnabled: true,
})

const { isMounted } = useIsOnMounted()
</script>

<style scoped>
.casino-tabs-wrapper {
  position: sticky;
  z-index: 9;
  top: 76px;

  margin: 0 calc(-1 * var(--spacing-200));
  margin-bottom: var(--spacing-200);
  padding: 0 var(--spacing-200);
  padding-bottom: var(--spacing-200);

  background-color: var(--background-base);
}

.casino-tabs {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);

  /* надо для того чтобы не вылазил контент при скроле */
  &::before {
    content: '';

    position: absolute;
    top: -23px;

    width: 101.5%;
    height: 30px;
    margin-left: calc(var(--spacing-050) * -1);

    background-color: var(--background-base);
  }
}

.casino-top-tabs {
  scrollbar-width: none;

  overflow: scroll hidden;
  display: flex;
  gap: var(--spacing-300);

  font: var(--desktop-text-2xl-semibold);

  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.casino-top-tab {
  color: var(--text-secondary);
  text-decoration: none;
  white-space: nowrap;

  &.active {
    color: var(--text-primary);
  }
}

.casino-bottom-tabs-wrapper {
  display: flex;
}

.casino-bottom-tabs {
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  gap: var(--spacing-100);
}

.casino-bottom-tabs::-webkit-scrollbar {
  display: none;
}

.casino-bottom-tab {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  padding: var(--spacing-075) var(--spacing-150);

  font: var(--desktop-text-sm-semibold);
  color: var(--text-secondary);
  text-decoration: none;
  white-space: nowrap;

  background-color: var(--background-primary);
  border-radius: var(--border-radius-100, 8px);

  transition: 0.2s ease-in-out;

  &.active {
    color: var(--text-primary);
    background-color: var(--button-gray-default);
  }

  &:hover {
    color: var(--text-primary);
    background-color: var(--button-gray-default);
  }
}

.filters {
  position: relative;
  display: flex;

  &::after {
    content: '';

    position: absolute;
    left: -48px;

    width: 48px;
    height: 100%;

    background: linear-gradient(90deg, rgb(17 17 24 / 0%) 0%, #111118 100%);
  }
}

.bottom-tab-icon {
  width: 16px;
  height: 16px;
}
</style>
